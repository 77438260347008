<script>
export default {
  name: 'text-input',
  props: {
    name: { type: String, required: true },
    value: { type: String, required: true },
    type: { type: String, default: 'text' },
    error: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    hideError: { type: Boolean, default: false },
  },
  methods: {
    onInputChange(...args) {
      this.$emit('input', ...args);
    },
  },
};
</script>

<template>
  <div class="text-input" :class="{ error }">
    <input
      autocomplete="off"
      required
      :disabled="disabled"
      :name="name"
      :id="name"
      :readonly="readonly"
      :type="type"
      :value="value"
      @input="onInputChange"
    />
    <label :for="name">{{ label }}</label>
    <span v-if="!hideError && error" class="error-message">{{ error }}</span>
  </div>
</template>

<style lang="stylus" scoped>
@require "../styles/definitions"

div.text-input
  font-family primary-font-family
  font-size 14px
  font-weight 400
  margin-bottom 8px
  margin-top 12px
  position relative
  width 100%
  label
    color rgba(c-black, .54)
    left 0
    padding 0
    pointer-events none
    position absolute
    text-transform capitalize
    top 24px
    transition .2s ease all
  input
    border none
    border-bottom 2px solid c-black-60
    font-family inherit
    font-size 14px
    line-height 17px
    margin-top 16px
    outline none
    padding 6px 0 7px
    width 100%
    &:focus + label, &:valid + label
      font-size 11px
      top 0
  .error-message
    color c-red
    font-size 11px
    margin-top 3px

div.text-input.error
  label, input
    color c-red
  input
    border-bottom-color c-red
</style>
