import { CRUX_TOKENS } from '../constants';
import webStorage from './webStorage';
import jsonTryParse from './jsonTryParse';

export default function() {
  const tokenInfo = jsonTryParse(webStorage.retrieve(CRUX_TOKENS));

  if (!tokenInfo || !tokenInfo.access_token) {
    return null;
  }

  return tokenInfo.access_token;
}
