import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

const initialUserDetails = {
  email: '',
  isAuthenticated: false,
};

const getDefaultState = (state = {}) => ({
  user: initialUserDetails,
});

const getStoreConfig = ({ router, state }) => {
  const initialState = getDefaultState(state);

  const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
  });

  const storeConfig = {
    state: initialState,
    actions: {
      setUser: (context, userDetails) => {
        context.commit('SET_USER', userDetails);
      },
      removeUser: context => {
        context.commit('SET_USER', initialUserDetails);
      },
    },
    mutations: {
      SET_USER: (state, { email = '', isAuthenticated = false }) => {
        state.user.email = email;
        state.user.isAuthenticated = isAuthenticated;
      },
    },
    getters: {
      getUser: state => state.user,
    },
    plugins: [vuexLocal.plugin],
  };

  return storeConfig;
};

const initStore = ({ router, state }) => {
  Vue.use(Vuex);

  const storeConfig = getStoreConfig({ router, state });
  const store = new Vuex.Store(storeConfig);

  return store;
};

export default initStore;
