import Router from 'vue-router';
import Root from './routes';
import Login from './routes/Login';

import getToken from './helpers/getToken';
import webStorage from './helpers/webStorage';
import { CRUX_TOKENS } from './constants';

export const routeOpts = {
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Root,
      meta: { requiresAuth: true },
    },
    {
      path: '/login',
      component: Login,
      meta: { requiresAuth: false },
    },
  ],
};

const router = new Router(routeOpts);

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token;

    if (to.query.accessToken) {
      token = to.query.accessToken;

      webStorage.store(
        CRUX_TOKENS,
        JSON.stringify({ access_token: token }),
        true
      );
      router.replace('/');
    } else {
      token = getToken();
    }

    if (!token) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
