<script>
import { mapActions } from 'vuex';
import { Button, TextInput } from '../components';
import { authService } from '../services';
import { webStorage } from '../helpers';
import { CRUX_TOKENS } from '../constants';

export default {
  name: 'login',
  components: {
    'v-button': Button,
    'text-input': TextInput,
  },
  data() {
    return {
      formFields: {
        username: '',
        password: '',
      },
      error: '',
      loading: false,
    };
  },
  computed: {
    isSubmitDisabled() {
      const { username, password } = this.formFields;

      return !(username.trim() && password.trim());
    },
  },
  methods: {
    ...mapActions(['setUser']),
    onInputChange({ target: { name, value } }) {
      this.formFields[name] = value;
      this.error = '';
    },
    logIn(token) {
      webStorage.store(CRUX_TOKENS, JSON.stringify(token), true);

      this.setUser({
        email: this.formFields.username.trim(),
        isAuthenticated: true,
      });

      this.$router.push('/');
    },
    async onSubmitForm() {
      if (this.isSubmitDisabled) {
        return;
      }

      this.loading = true;

      try {
        const token = await authService.login(this.formFields);

        this.logIn(token);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <section class="login">
    <div class="login-wrapper">
      <h2 class="login-title">Crux Portal - Log In</h2>
      <form class="login-form" @submit.prevent="onSubmitForm" novalidate>
        <text-input
          label="username"
          name="username"
          hideError
          :value="formFields.username"
          :error="error"
          @input="onInputChange"
        />
        <text-input
          type="password"
          name="password"
          label="password"
          :value="formFields.password"
          :error="error"
          @input="onInputChange"
        />
        <div class="login-button">
          <v-button type="submit" :disabled="isSubmitDisabled || loading">
            login
          </v-button>
        </div>
      </form>
    </div>
  </section>
</template>

<style lang="stylus" scoped>
@require "../styles/definitions"

.login
  align-items center
  display flex
  flex-direction column
  height 100%
  justify-content center

.login-wrapper
  border-radius 4px
  box-shadow c-shadow
  max-width 372px
  width 100%

.login-title
  color c-black-80
  font-family primary-font-family
  font-size 21px
  font-weight 400
  line-height 28px
  padding 16px
  text-align center

.login-form
  display flex
  flex-direction column
  padding 16px 16px 8px

.login-button
  align-items center
  display flex
  padding-top 42px
</style>
