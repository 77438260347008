import {
  DEFAULT_ERROR_MESSAGE,
  REQUEST_ERROR_CODES,
  CRUX_TOKENS,
} from '../constants';
import router from '../router';
import { store } from '../main';
import webStorage from './webStorage';
import getToken from './getToken';
import { RequestError } from './exceptions';

const contentTypes = {
  json: 'application/json',
  text: 'text/html',
};

const responseErrorActions = {
  [REQUEST_ERROR_CODES.unauthorized]: () => {
    webStorage.delete(CRUX_TOKENS);
    store.dispatch('removeUser');
    router.push('/login');
  },
};

const encodeQueryData = data => {
  if (!data) {
    return '';
  }

  try {
    const queryStringStart = '?';

    return Object.keys(data).reduce((queryString, key, index) => {
      const paramStringStart = index ? '&' : '';
      const paramString = `${paramStringStart}${encodeURIComponent(
        key
      )}=${encodeURIComponent(data[key])}`;

      return queryString + paramString;
    }, queryStringStart);
  } catch (e) {
    throw new TypeError('Error: incorrect query data format.');
  }
};

const getResponseData = async response => {
  const contentType = response.headers.get('content-type');

  if (!contentType || contentType.includes(contentTypes.text)) {
    const responseText = await response.text();

    return { message: responseText };
  }

  if (contentType.includes(contentTypes.json)) {
    return response.json();
  }

  throw new TypeError('Error: incorrect received data format.');
};

const handleResponseError = responseStatus => {
  if (!responseErrorActions[responseStatus]) {
    return;
  }

  responseErrorActions[responseStatus]();
};

const sendRequest = async (requestUrl = '', options = {}) => {
  let response;
  const requestHeaders = new Headers({
    'Content-Type': contentTypes.json,
  });

  const authToken = getToken();

  if (authToken) {
    requestHeaders.append('Authorization', `Bearer ${authToken}`);
  }

  const requestOptions = {
    headers: requestHeaders,
    ...options,
  };

  try {
    response = await fetch(requestUrl, requestOptions);
  } catch (e) {
    throw new TypeError(DEFAULT_ERROR_MESSAGE);
  }

  const responseData = await getResponseData(response);

  if (!response.ok) {
    handleResponseError(response.status);

    throw new RequestError(response.status, responseData);
  }

  return responseData;
};

export const sendGetRequest = (url, data) => {
  const requestUrl = url + encodeQueryData(data);
  const requestOptions = {
    method: 'GET',
  };

  return sendRequest(requestUrl, requestOptions);
};

export const sendPostRequest = (url, data) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return sendRequest(url, requestOptions);
};
