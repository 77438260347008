import { DEFAULT_ERROR_MESSAGE } from '../constants';

export class RequestError extends Error {
  constructor(status = '', responseData) {
    super();

    const errorData = responseData || {};

    this.message = errorData.message || DEFAULT_ERROR_MESSAGE;
    this.errors = errorData.errors;
    this.status = status;
    this.name = 'RequestError';
  }
}
