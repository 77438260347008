<script>
import { mapGetters } from 'vuex';
import { version } from '../../package.json';
import { windowHelper } from '../helpers';
import config from '../config';

export default {
  computed: {
    version() {
      return `v${version}`;
    },
    ...mapGetters(['getUser']),
  },
  methods: {
    goToExternal: function(link) {
      windowHelper.goToExternal(link);
    },
    goToExternalWithAccess: function() {
      windowHelper.goToExternalWithAccess(config.externalAccessUrl);
    },
  },
};
</script>

<template lang="html" v-if="getUser.isAuthenticated">
  <div class="home-content">
    <sui-card-group :items-per-row="3">
      <sui-card class="raised teal cardHover">
        <sui-card-content>
          <div class="icon-holder">
            <sui-icon size="massive" class="center" color="teal" name="react" />
          </div>
        </sui-card-content>
        <sui-card-content>
          <sui-card-Header>Sample App (custom login)</sui-card-Header>
          <sui-card-Description>
            App with a custom, self-hosted login module (automatically logs you
            in)
          </sui-card-Description>
        </sui-card-content>
      </sui-card>

      <sui-card
        class="clickable raised blue cardHover"
        v-on:click="goToExternalWithAccess()"
      >
        <sui-card-content>
          <div class="icon-holder">
            <sui-icon size="massive" class="center" color="blue" name="react" />
          </div>
        </sui-card-content>
        <sui-card-content>
          <sui-card-Header>Sample App (Okta-hosted login)</sui-card-Header>
          <sui-card-Description>
            App with an Okta-hosted login module (automatically logs you in)
          </sui-card-Description>
        </sui-card-content>
      </sui-card>

      <sui-card
        class="clickable raised grey cardHover"
        v-on:click="goToExternal('https://www.cruxinformatics.com/')"
      >
        <sui-card-content>
          <div class="icon-holder">
            <sui-icon
              name="external"
              size="massive"
              class="center"
              color="grey"
            />
          </div>
        </sui-card-content>
        <sui-card-content>
          <sui-card-Header>External Site</sui-card-Header>
          <sui-card-Description>
            Crux website (opens in a new tab)
          </sui-card-Description>
        </sui-card-content>
      </sui-card>
    </sui-card-group>
  </div>
</template>

<style lang="stylus" scoped>
@require "../styles/definitions"

.home-content
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  justify-content: center;
  padding: 24px;
  width: 70%;
  margin: auto;

.icon-holder {
  display: flex;
  justify-content: center;
}

.cardHover {
  position: relative;
  top: 0;
  transition: top ease 0.5s
}

.cardHover:hover {
  top: -5px;
}

.clickable
  cursor: pointer;
</style>
