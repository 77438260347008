<script>
import { mapActions } from 'vuex';
import { Navbar } from './components';
import { userService } from './services';

export default {
  name: 'app',
  components: {
    'v-navbar': Navbar,
  },
  created() {
    if (this.$route.path !== '/login') {
      this.getUserProfile();
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async getUserProfile() {
      try {
        const user = await userService.getProfile();

        this.setUser({
          email: user.email,
          isAuthenticated: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<template>
  <div class="container">
    <v-navbar></v-navbar>
    <main class="main">
      <router-view></router-view>
    </main>
  </div>
</template>

<style lang="stylus">
@require "./styles/reset"
global-reset()
@require "./styles/base"

.container
  height calc(100% - 44px)
  .main
    padding-top 44px
</style>
