import getToken from './getToken';

export default {
  goToExternal: link => {
    const win = window.open(link, '_blank');

    win.focus();
  },
  goToExternalWithAccess: link => {
    const token = getToken();

    const win = window.open(link + '?accessToken=' + token, '_blank');

    win.focus();
  },
};
