export default {
  store: (key, value, persist) => {
    if (persist) {
      window.localStorage.setItem(key, value);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  },
  delete: key => {
    window.sessionStorage.removeItem(key);

    window.localStorage.removeItem(key);
  },
  retrieve: key => {
    const value =
      window.sessionStorage.getItem(key) || window.localStorage.getItem(key);

    return value;
  },
};
