import Vue from 'vue';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import promiseFinally from 'promise.prototype.finally';
import SuiVue from 'semantic-ui-vue';
import App from './App';
import initStore from './store';
import router from './router';

promiseFinally.shim();

Vue.mixin({
  created() {},
});

Vue.use(Router);
Vue.use(SuiVue);
Vue.config.ignoredElements = ['loader'];

if (!document.querySelector('main')) {
  document.body.appendChild(document.createElement('main'));
}

export const store = initStore({ router });

sync(store, router);

// eslint-disable-next-line no-new
new Vue({
  el: 'main',
  router,
  store,
  template: '<App/>',
  components: { App },
});

if (module.hot) {
  module.hot.addStatusHandler(status => {
    if (status === 'apply') {
      document //
        .querySelectorAll('link[href][rel=stylesheet]')
        .forEach(link => {
          const nextStyleHref = link.href.replace(
            /(\?\d+)?$/,
            `?${Date.now()}`
          );

          // eslint-disable-next-line no-param-reassign
          ink.href = nextStyleHref;
        });
    }
  });
}

export default {
  App,
};
