export default function(data) {
  if (!data) {
    return null;
  }

  try {
    return JSON.parse(data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('JSON.parse failed for args:', args);

    return null;
  }
}
