<script>
import { mapActions, mapGetters } from 'vuex';
import { CRUX_TOKENS } from '../constants';
import webStorage from '../helpers/webStorage';

export default {
  name: 'v-navbar',
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    ...mapActions(['setUser']),
    logout: function() {
      webStorage.delete(CRUX_TOKENS);
      this.setUser({
        email: '',
        isAuthenticated: false,
      });
      this.$router.push('/login');
    },
  },
};
</script>

<template>
  <header class="navbar">
    <router-link to="/" class="navbar-item logo">
      <div
        v-html="require(`../static/media/logo/crux-icon-white.svg`)"
        class="logo-img"
      />
      &nbsp;
      <div class="logo-text">
        Crux-Okta Portal PoC
      </div>
    </router-link>
    <div v-if="getUser.isAuthenticated" class="nav-group">
      <div v-if="getUser.email" class="navbar-item item">
        {{ getUser.email }}
      </div>
      <div v-on:click="logout" class="navbar-item item clickable">Logout</div>
    </div>
  </header>
</template>

<style lang="stylus" scoped>
@require '../styles/definitions'

header.navbar
  background c-black-90
  color rgba(c-white, .9)
  display flex
  font-size 14px
  justify-content space-between
  position fixed
  width 100%
  .navbar-item
    padding 13px 16px
    position relative
    &:after, &:before
      background rgba(c-white, .08)
      content ""
      height 100%
      position absolute
      top 0
      width 1px
    &:after
      right 0
    &:before
      left 0
  .logo
    align-items center
    display flex
    color rgba(c-white, .9)
    font-weight 700
    text-decoration none
    .logo-img
      height 18px
      width 35px
  .nav-group
    display flex
.clickable
  cursor: pointer;
</style>
