<script>
export default {
  name: 'v-button',
  props: {
    type: { type: String, default: 'button' },
    disabled: { type: Boolean, default: false },
    onClick: { type: Function, default: () => {} },
  },
};
</script>

<template>
  <button class="button" type="submit" :disabled="disabled" @click="onClick">
    <slot />
  </button>
</template>

<style lang="stylus" scoped>
@require "../styles/definitions"

.button
  background-color c-black-40
  border-radius 4px
  box-shadow none
  color c-white
  cursor pointer
  font-family primary-font-family
  font-size 14px
  font-weight 500
  line-height 1.75
  min-width 64px
  padding 8px 22px
  text-transform uppercase
  transition background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  width 100%
  &:not(:disabled):hover
    box-shadow c-shadow
    background-color c-white-20
  &:disabled
    background-color rgba(c-black, 0.12)
    cursor default
    color rgba(c-black, 0.26)
</style>
